<template>
  <div class="container mt-3">
    <div v-if="luscherResults === false" class="lucsher-test">
      <div v-if="checkAccess === 0"><h1 class="text-center mt-3 mb-3">Время сдачи теста еще не настало!</h1></div>
      <div v-else>
        <div class="lucsher-test-description">
          <div v-if="colorsCount === null" class="lucsher-test-description-first-description  mb-4">
            <h5>Выберите цвет, который нравится Вам больше всего.</h5>
          </div>
          <div v-else class="lucsher-test-description-last-descriptions mb-2">
            <h5>Из оставшихся {{ 8 - colorsCount }} цветов выберите тот, что нравится Вам больше.</h5>
          </div>
        </div>
        <div class="luscher-test-buttons d-flex flex-row justify-content-between row mx-3">
          <button class="col-xs-4 mb-3" v-for="(score, index) in luscherColors" :style="{background: '#' + score.hex}" :key="index"
                  @click="choiceOfColor(score.id)" :disabled="score.isDisabled"></button>
        </div>
      </div>
    </div>
    <div v-else class="lucsher-results">
      <div class="luscher-results-text">
        <h3 class="text-center">Интерпретация цветовых выборов</h3>
      </div>
      <div class="luscher-results-co">
        <h4>Суммарное отклонение от аутогенной нормы равен</h4>
        <p>{{ luscherResults.co_score }} - {{ luscherResults.lusher_co_text }}</p>
      </div>
      <div class="luscher-results-vk">
        <h4>Вегетативный коэффицент равен</h4>
        <p>{{ luscherResults.vk_score }} - {{ luscherResults.lusher_vk_text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "Luscher",
  data() {
    return {
      luscherAnswers: [],
      colorsCount: null,
      attemptCount: null,
      resultDescription: {}
    }
  },
  methods: {
    ...mapActions('luscher', ['GET_LUSCHER_COLORS', 'POST_LUSCHER_ANSWERS', 'GET_LUSCHER_ANSWERS']),
    ...mapActions('checkAccess', ['GET_CHECK_ACCESS']),

    async choiceOfColor(id) {

      if (this.luscherColors.length !== this.colorsCount) {
        this.colorsCount++
        this.luscherAnswers.push({
          order: this.colorsCount,
          color_id: id
        })
        this.luscherColors[id - 1].hex = 'FFFFFF'
        this.luscherColors[id - 1].isDisabled = true

        if (this.luscherColors.length - 1 === this.luscherAnswers.length) {
          for (let i = 0; i < this.luscherColors.length; i++) {
            if (!this.luscherColors[i].isDisabled) {
              this.luscherColors[i].hex = 'FFFFFF'
              this.luscherColors[i].isDisabled = true
              this.colorsCount++
              this.luscherAnswers.push({
                order: this.colorsCount,
                color_id: this.luscherColors[i].id
              })
            }
          }

        }
      }
      if (this.luscherColors.length === this.colorsCount && this.attemptCount === null) {
        this.luscherAnswers = []
        this.colorsCount = null
        this.attemptCount++

        await this.GET_LUSCHER_COLORS();

        for (let i = 0; i < this.luscherColors.length; i++) {
          this.luscherColors[i].isDisabled = false
        }
      } else if (this.luscherColors.length === this.colorsCount && this.attemptCount === 1) {
        await this.POST_LUSCHER_ANSWERS(this.luscherAnswers)
        await this.GET_LUSCHER_ANSWERS();
      }
    }
  },
  computed: {
    ...mapState('luscher', ['luscherColors', 'luscherResults']),
    ...mapState('checkAccess', ['checkAccess']),
    getCo() {
      return this.luscherResults[1];
    }
  },
  async mounted() {
    await this.GET_CHECK_ACCESS('lusher');
    await this.GET_LUSCHER_ANSWERS();

    if (this.luscherAnswers) {
      await this.GET_LUSCHER_COLORS();
      for (let i = 0; i < this.luscherColors.length; i++) {
        this.luscherColors[i].isDisabled = false
      }
    }
  }
}
</script>

<style scoped>
.luscher-test-buttons button {
  width: 100px;
  height: 100px;
  border: initial;
}
</style>